/* main styles */
[data-video].bg-video {
    position: relative;
    height: 380px;
    overflow: hidden;
}

[data-video] iframe,
[data-video] video {
    display: block;
}

.fluid-video {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

.fluid-video iframe,
.fluid-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* decor styles */
[data-video] .btn-play em {
    font-style: normal;
    display: none;
}

[data-video].playing .btn-play span,
[data-video].playing.paused .btn-play em {
    display: none;
}

[data-video].playing .btn-play em,
[data-video].playing.paused .btn-play span {
    display: inline;
}

[data-video] .btn-play,
[data-video] .btn-pause {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    width: 50px;
    height: 50px;
    text-align: center;
    text-decoration: none;
    padding: 14px 5px;
    background: #0900ed;
    color: #fff;
}

[data-video] .btn-pause {
    left: 70px;
}