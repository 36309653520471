.section-video {
	.inner {
		position: relative;
		z-index: 1;
	}

	.img {
		position: relative;
		padding-top: 70%;

		@include media('>=tablet') {
			padding-top: 50%;
		}

		@include media('>=desktop') {
			padding-top: 35%;
		}
	}

	img {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		top: 0;
		object-fit: cover;
		object-position: center;
	}

	.container {
		padding: 0;
	}

	.section-video-image {
		position: relative;
	}

	.btn-video {
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: none;
		font-size: 0;
		line-height: 1;
	}

	.btn-play {
		padding: 0;
		width: 72px;
		height: 72px;
		transition: all $animation-speed;
	}
}

.btn-video {
	&:hover {
		opacity: 0.6;
	}
}

#lightbox-video {
	padding: 0;

	iframe {
		vertical-align: top;
		margin-bottom: 0;
	}

	.fancybox-close-small {
		background: $olive-100;
	}
}