#js-page-cursor {
	width: 20px;
	height: 20px;
	z-index: 6000;
    pointer-events: none;
	background: transparent;
	transition: .4s background , .4s width , .4s height;
	z-index: 10;
}

.hover-arrow-next,
.hover-arrow-prev {
	width: 102px !important;
	height: 81px !important;
	background: transparent url('../images/prev.png') no-repeat center/contain !important;
	z-index: 10;
}

.hover-arrow-next {
	background: transparent url('../images/next.png') no-repeat center/contain !important;
}
