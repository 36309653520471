// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
    position: relative;
    display: inline-block;
    padding: $btn-padding;
    font-size: $btn-font-size;
    text-align: center;
    line-height: $btn-line-height;
    vertical-align: top;
    text-transform: uppercase;
    font-weight: $medium;
    font-family: $base-font-gravesend;
    transition: all $animation-speed;
    min-width: 214px;
    letter-spacing: 0.2em;

    &-default {
        color: $btn-color-default;
        background: $btn-background-default;

        &:hover {
            color: $btn-hover-color-default;
            background: $btn-hover-background-default;
        }
    }

    &-primary {
        color: $btn-color-primary;
        background: $btn-background-primary;

        &:hover {
            color: $btn-hover-color-primary;
            background: $btn-hover-background-primary;
        }
    }

    &-secondary {
        color: $btn-color-secondary;
        background: $btn-background-secondary;

        &:hover {
            color: $btn-hover-color-secondary;
            background: $btn-hover-background-secondary;
        }
    }

    &-ico {
        text-align-last: left;
        padding-right: 30px;

        &:before {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url('../images/arrow-next.png');
            width: 8px;
            height: 18px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &-sm {
        padding: $btn-padding-sm;
        font-size: $btn-font-size-sm;
        line-height: $btn-line-height-sm;
        border-radius: $btn-border-radius-sm;
    }

    &-lg {
        padding: $btn-padding-lg;
        font-size: $btn-font-size-lg;
        line-height: $btn-line-height-lg;
        border-radius: $btn-border-radius-lg;
    }
}

.btn-group {
    margin: 0 -5px -10px;
    display: flex;
    flex-wrap: wrap;

    .btn {
        margin: 0 5px 10px;
    }
}