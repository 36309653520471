.section-gallery {
	position: relative;
	z-index: 1;
	
	.container {
		max-width: none;
		padding-right: 25px;
		padding-left: 25px;

		@include media('>=widescreen') {
			padding-left: 128px;
		}
	}

	.heading-holder,
	.inner {
		background-color: #efebeb;
		padding-top: 60px;
		padding-bottom: 40px;

		@include media('>=tablet') {
			padding-top: 100px;
		}

		@include media('>=desktop') {
			padding-top: 125px;
			padding-bottom: 70px;
		}
	}

	.heading-holder {
		padding-bottom: 0;
	}

	.inner {
		overflow: hidden;
		padding-top: 0;
		background: linear-gradient(#efebeb 53%, #e5e3de 0);
	}

	.section-heading {
		position: relative;
		max-width: 580px;
		letter-spacing: .042em;
		padding-bottom: 20px;
		z-index: 2;

		@include media("≥desktop") {
			width: 55%;
			padding-bottom: 57px;
		}

		h3 {
	
			@include media('>=576px') {
				margin-bottom: 20px;
			}
	
			@include media('>=widescreen') {
				margin-bottom: 2.7em;
			}
		}
	}

	p {
		margin-bottom: 0;
	}

	.carousel-holder {
		position: relative;
	}

	.swiper {
		overflow: visible;
	}
	
	.gallery-carousel {
		max-width: 766px;
		margin-right: auto;
		margin-left: auto;
	
		@include media('>=desktop') {
			max-width: 70%;
			margin-left: -30px;
		}
	
		@include media('>=widescreen') {
			max-width: 766px;
		}
	
		@include media('>=1440px') {
			max-width: 60vw;
		}
	
		.swiper-slide {
			&:not(.swiper-slide-visible),
			&:not(.swiper-slide-active) {
				opacity: 0 !important;
			}
	
			&.swiper-slide-active,
			&.swiper-slide-visible {
				opacity: 1 !important;
			}
		}
	}
	
	.card-carousel {
		.img {
			position: relative;
			padding-top: 90%;
	
			@include media('>=tablet') {
				padding-top: 78%;
			}
	
			@include media('>=1440px') {
				padding-top: 60%;
			}
	
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	
		.card-content {
			position: relative;
			overflow: hidden;
	
			@include media('>=tablet') {
				margin: 0 25px;
			}
	
			@include media('>=desktop') {
				margin: 0 30px;
			}
	
			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-image: radial-gradient(
					ellipse at top,
					rgba(15, 24, 33, 0) 32%,
					rgb(15 24 33 / 76%) 52%,
					rgb(15 24 33) 72%
				);
				background-position-y: 0;
				background-size: 370%;
				background-position-x: center;
				pointer-events: none;
			}
		}
	
		.card-heading {
			position: absolute;
			width: 100%;
			left: 20px;
			bottom: 30px;
			text-transform: uppercase;
			font-family: $base-font-gravesend;
			letter-spacing: .27em;
			font-weight: 300;
			z-index: 1;
			pointer-events: none;
			
			@include media('>=tablet') {
				left: 55px;
			}
	
			h5 {
				font-size: 20px;
				color: #cabead;
				margin-bottom: 0;
				font-weight: 300;
				letter-spacing: .046em;
	
				@include media('>=tablet') {
					font-size: 27px;
				}
			}
	
			p {
				font-size: 11px;
				color: #fbfcfc;
			}
		}
	
		.card-footer {
			font-family: $base-font-gravesend;
			padding: 15px;
			background-color: $white;
			display: grid;
			grid-template-columns: 50px 1fr;
			grid-gap: 10px;
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: .06em;
			font-weight: 500;
			box-shadow: 0 5px 20px 5px #313b4247;
	
			@include media('>=576px') {
				font-size: 14px;
			}
	
			@include media('>=tablet') {
				grid-template-columns: 70px 1fr;
				padding: 25px;
				grid-gap: 20px;
				font-size: 16px;
				margin-right: 0;
			}
	
			strong {
				font-size: 14px;
				display: block;
				font-weight: 500;
				text-transform: uppercase;
				color: #897f6f;
	
				@include media('>=576px') {
					font-size: 16px;
				}
	
				@include media('>=tablet') {
					font-size: 22px;
				}
			}
	
			p {
				margin-bottom: 0;
				letter-spacing: .1em;
			}
		}
	}
	
	.right-carousel-wrap {
		position: absolute;
		top: 140px;
		z-index: 1;
		overflow: hidden;
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
	
		@include media('>=desktop') {
			right: -19vw;
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	
		@include media('>=widescreen') {
			top: 70px;
		}
	
		@include media('>=1440px') {
			right: -7.8vw;
		}
	}
	
	.right-carousel {
		position: relative;
		z-index: 1;
		
		.img {
			position: relative;
			padding-top: 83%;
			width: 430px;
	
			@include media('>=1440px') {
				width: calc(40vw - 155px);
			}
	
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	
		.carousel-slide {
			width: 100%;
			flex: 0 0 100%;
			position: relative;
			height: 100%;
	
			&:not(:first-child) {
				position: absolute;
				inset: 0;
			}
		
			&.swiper-slide-prev {
				z-index: 999 !important;
			}
		
			&.swiper-slide-active {
				z-index: 1000 !important;
			}
	
			.img {
				span {
					position: relative;
					z-index: 2;
					font-weight: 700;
					background: red;
					padding: 20px;
				}
			}
	
		}
	}
	
	.slides-counter {
		display: flex;
		align-items: center;
		color: #3b444b;
		font-size: 22px;
		font-family: $base-font-gravesend;
		font-weight: 300;
		margin-bottom: 20px;
	
		@include media('>=widescreen') {
			position: absolute;
			top: 27%;
			left: -73px;
			margin-bottom: 0;
		}
	
		.separator {
			margin-left: 10px;
			margin-right: 5px;
		}
	
		.current-index {
			font-size: 45px;
			min-width: 22px;
			color: #938876;
			text-align: center;
		}
	}
	
	&[data-direction="next"] .img {
		animation: visual_left_out 0.5s ease both;
	}
	
	&[data-direction="prev"] .img {
		animation: visual_right_out 0.5s ease both;
	}
	
	&[data-direction="next"] .swiper-slide-active .img {
		animation: visual_left_in 0.5s ease both;
	}
	
	&[data-direction="prev"] .swiper-slide-active .img {
		animation: visual_right_in 0.5s ease both;
	}
}

@keyframes visual_left_out {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-100%);
	}
}

@keyframes visual_right_out {
	0% {
		transform: translateX(0px);
	}

	100% {
		transform: translateX(100px);
	}
}

@keyframes visual_left_in {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(0%);
	}
}

@keyframes visual_right_in {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(0%);
	}
}