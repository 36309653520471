.block-three-col-carousel {
	background-color: #efebeb;
	padding: 0 0 93px;

	.swiper-pagination {
		transform: none;
		position: static;
		margin-top: 20px;

		@include media('>=tablet') {
			justify-content: flex-start;
		}

		@include media(">=desktop") {
			margin-top: 47px;
		}

		.swiper-pagination-bullet {
			&:first-child {
				@include media('>=tablet') {
					margin-left: 0 !important;
				}
			}
		}
	}

	.carousel-wrap {
		position: relative;
		align-items: flex-start;
		margin: 0 -15px;

		@include media('>=tablet') {
			display: flex;
			margin: 0 -40px;
		}

		>* {
			overflow: hidden;
			position: relative;
		}
	}

	.left-carousel,
	.right-carousel {
		position: relative;
		z-index: 1;
	}

	.swiper-slide {
		opacity: 0 !important;

		&.swiper-slide-active {
			opacity: 1 !important;
		}
	}

	.left-carousel--slide,
	.right-carousel--slide {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		overflow: hidden;
		z-index: 2;

		&:first-child {
			position: relative;
		}

		&.swiper-slide-prev {
			z-index: 999 !important;
		}

		&.swiper-slide-active {
			z-index: 1000 !important;
		}

		.img {
			padding-top: 50%;

			@include media('>=tablet') {
				padding-top: 130%;
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}


	.left-carousel-wrap {
		flex-basis: 50vw;

		.img {
			@include media('>=widescreen') {
				padding-top: 88%;
			}
		}
	}

	.right-carousel-wrap {
		width: 28vw;
		right: -18vw;
		position: absolute;
		top: 0;
		z-index: 1;

		@include media('<tablet') {
			display: none;
		}

		@include media('>=1367px') {
			right: -12vw;
		}
	}

	.three-col-carousel-holder {
		flex-basis: 300px;
		padding: 20px 40px;
		flex-shrink: 0;

		@include media('>=tablet') {
			padding: 100px 20px 0 20px;
		}

		@include media('>=widescreen') {
			flex-basis: 432px;
			padding: 77px 50px 0 40px;
		}
	}

	.swiper-button {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	&[data-direction="next"] {
		.img,
		.text-content {
			animation: image_left_out 0.5s ease both;
		}

		.swiper-slide-active {

			.img,
			.text-content {
				animation: image_left_in 0.5s ease both;
			}
		}
	}

	&[data-direction="prev"] {
		.img,
		.text-content {
			animation: image_right_out 0.5s ease both;
		}

		.swiper-slide-active {

			.img,
			.text-content {
				animation: visual_right_in 0.5s ease both;
			}
		}
	}

	.text-content {
		font-weight: 400;
		color: #282623;
		font-size: 14px;
		letter-spacing: .01em;

		h5 {
			font-weight: 300;
			color: #282623;
			font-size: 20px;
			text-transform: uppercase;
			line-height: 1.2;
			max-width: 276px;
			letter-spacing: .04em;

			@include media('>=phone') {
				font-size: 25px;
			}
		}

		p {
			@include media('>=desktop') {
				margin-bottom: 47px;
			}
		}

		ul {
			@extend %listreset;

			li {
				position: relative;
				padding-left: 20px;

				@include media('>=desktop') {
					padding-left: 34px;
				}

				+li {
					margin-top: 5px;

					@include media('>=phone') {
						margin-top: 10px;
					}
				}

				&:before {
					position: absolute;
					top: 0.4em;
					left: 0;
					content: '';
					width: 7px;
					height: 7px;
					background: #282623;
					border-radius: 50%;
				}
			}
		}
	}
}