// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site
// -----------------------------------------------------------------------------
[id="footer"] {
    position: relative;
    color: $gray-400;
	z-index: 2;

    a {
        color: inherit;
    }
}

.footer-panel {
    padding: 80px 0 50px 0;
    background: $gray-700;

    @include media('≥desktop') {
        padding: 107px 0 86px 0;
    }

    .container {
        max-width: none;
        padding: 0 28px;

        @include media('>=widescreen') {
            padding-left: 128px;
            padding-right: 50px;
        }
    }

    .sub-title {
        margin-bottom: 3.6em;
        color: $gray-500;
    }

    h3 {
        margin-bottom: .55em;

        mark {
			margin-left: -0.09em;
            color: $gray-400;
        }
    }

    .section-heading-text {
        margin-bottom: 0.95em;
        letter-spacing: .03em;
        max-width: 430px;

        p {
            margin: 0 0 .5em;
        }
    }
}

.footer-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media('≥widescreen') {
        flex-wrap: nowrap;
    }

    .footer-col {
        width: 100%;
        padding-top: 30px;
        letter-spacing: .05em;

        @include media('≥phone') {
            padding-top: 45px;
            width: 48%;
            max-width: 600px;
        }

        @include media('≥widescreen') {
            padding-left: 6.6%;
            width: 25%;
        }

        &:first-child {
            padding-top: 0;
            padding-left: 0;
            width: 100%;

            @include media('≥widescreen') {
                width: 49%;
            }
        }

        &:last-child {
            padding-left: 3%;

            @include media('≥widescreen') {
                padding-left: 1.6%;
            }
        }
    }
}

.address {
    position: relative;
    margin: 0;
    padding: 0 0 0 44px;
    font-style: normal;
    line-height: calc(31/16);

    @include media('≥phone') {
        padding: 0 0 0 27px;
    }

    &:after {
        position: absolute;
        top: 3px;
        left: 0;
        width: 16px;
        height: 24px;
        content: '';
        background: url('../images/location.png') no-repeat;
        background-size: contain;
    }
}

.list-contact {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: calc(31/16);

    >li {
        position: relative;
        padding-left: 37px;
        transition: .4s opacity;

        &:hover {
            opacity: .7;
        }
    }

    .icon-contact {
		position: absolute;
		top: 50%;
		left: 0;
		width: 20px;
		transform: translateY(-50%);
		font-size: 0;
    }
}

.footer-bar {
    padding: 28px 0;
    background: $gray-850;
    font-size: 14px;
    line-height: calc(18/14);
    color: #7b8385;

    .container {
        max-width: 100%;
    }

    .inner {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;

        @include media('≥tablet') {
            flex-direction: row;
        }
    }
}

.copyright {
    display: block;

    @include media('≥tablet') {
        padding-right: 20px;
    }
}

.footer-menu {
    margin: 0 0 15px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 38px;

    @include media('≥tablet') {
        margin: 0;
    }

    li {
        transition: .4s opacity;

        &:hover {
            opacity: .7;
        }
    }
}

.form-subscribe {
    @include media('≥phone') {
        display: flex;
    }

    input:not(textarea) {
        width: 100%;
        height: 64px;
        padding: 22px 20px;
        background: $gray-850;
        text-transform: uppercase;
        color: $gray-600;
        font-size: 16px;
        line-height: 20px;
        border: 0;
        font-weight: $light-font;
        letter-spacing: 0.1em;

        &::placeholder {
            color: $gray-600;
        }
    }

    .btn {
        height: 64px;
        width: 100%;
        border-radius: 0;

        @include media('≥phone') {
            min-width: 214px;
            width: 214px;
        }

        &-ico {
            &:before {
                width: 12px;
                height: 22px;
            }
        }
    }
}