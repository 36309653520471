.section-legal {
	font-size: 16px;
	color: #010101;
	letter-spacing: .03em;
	background-color: $white;

	.inner {
		margin: auto;
		padding: 75px 93px 118px;
	}

	address {
		font-style: normal;
		p {
			margin: 0;
		}
	}

	p,
	h5,
	ul,
	ol {
		margin-bottom: 28px;
	}

	h5 {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		letter-spacing: .2em;
	}

	a {
		color: #7f715e;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	ul {
		@extend %listreset;
		padding-left: 9px;

		li {
			position: relative;
			padding-left: 43px;

			+ li {
				margin-top: 11px;
			}

			strong {
				font-weight: 600;
			}

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 4px;
				width: 13px;
				height: 13px;
				border-radius: 50%;
				background-color: #cbc3a3;
			}
		}

		ul {
			li {
				padding-left: 33px;
				
				+ li {
					margin-top: 1px;
				}

				&:before {
					content: '-';
					position: static;
					padding-left: 0;
					background: transparent;
					width: auto;
					height: auto;
					margin-right: 10px;
				}
			}
		}
	}
}