.section-hero {
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    z-index: 2;

    .carousel-holder {
        position: relative;
        box-shadow: 0 -80px 80px 80px rgba($white, .1);
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: radial-gradient(ellipse at center, rgba(15, 24, 33, 0) 50%, rgba(15, 24, 33, .9) 65%, rgba(15, 24, 33, 1) 80%);
            background-position: 50% 0;
            height: 500px;
            background-size: 200% 200%;
            content: '';

            .bg-light & {
                display: none;
            }
        }
    }

    &-add {
        box-shadow: 0 -80px 80px 80px rgba($white, .1);

        @include media('<widescreen') {
            background: rgb(39 42 55 / 50%);
        }

        .container {
            max-width: 982px;
        }

        .sub-title {
            color: #d6cbb4;

            @include media('≥desktop') {
                margin-bottom: 1.7em;
            }
        }

        .horizon-line {
            &:after {
                top: 5px;

                @include media('≥widescreen-x') {
                    right: calc(100% + 100px);
                }
            }
        }

        h3 {
            color: $gray-600;

            @include media('≥desktop') {
                margin-bottom: 0.5em;
            }

            &.horizon-line {
                &:after {
                    top: 20px;
                }
            }
        }

        .section-heading-text {
            color: #fdfdfc;
            max-width: 545px;

            &-add {
                @include media('≥desktop') {
                    margin-left: 94px;
                }
            }
        }
    }

    &-add2 {
        box-shadow: 0 -80px 80px 80px rgba($white, .1);

        @include media('<widescreen') {
            background: rgb(39 42 55 / 50%);
        }

        .container {
            max-width: 982px;
        }

        .sub-title {
            color: #d6cbb4;

            @include media('≥desktop') {
                margin-bottom: 3.7em;
            }
        }

        .horizon-line {
            &:after {
                top: 15px;

                @include media('≥widescreen-x') {
                    right: calc(100% + 100px);
                }
            }
        }

        h3 {
            color: $gray-600;

            @include media('≥desktop') {
                margin-bottom: 0.5em;
            }
        }

        .section-heading-text {
            color: #fdfdfc;
            max-width: 530px;

            &-add {
                @include media('≥desktop') {
                    margin-left: 94px;
                }
            }
        }

        .section-hero-hold {
            padding: 156px 0 37px;
        }
    }

    &-large {
        .section-hero-hold {
            padding: 154px 0;
            min-height: 400px;

            @include media('≥desktop') {
                min-height: 500px;
            }

            @include media('≥widescreen') {
                min-height: 777px;
            }
        }

        .sub-title {
            margin-bottom: 3.7em;
        }

        h3.horizon-line {
            &:after {
                top: 19px;
            }
        }

        .section-heading-text {
            max-width: 390px;

            &-add2 {
                max-width: 580px;
                margin-left: 3%;
            }
        }

        &-add {
            .section-hero-hold {
                @include media('≥widescreen') {
                    min-height: 845px;
                }
            }
        }
    }

    &-hold {
        padding: 167px 0 73px;
    }
}

.hero-slider {
    @include media('≥phone') {
        padding: 0 80px;
    }

    @include media('≥desktop') {
        padding: 0 110px;
    }
}

.wrap-text {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    text-align: center;
    font-size: 16px;
    line-height: calc(23/16);
    color: $white;
    padding: 140px 15px 90px;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(-30px);

    @include media('≥tablet') {
        min-height: 530px;
        padding: 90px 15px 140px;
    }

    @include media('>=widescreen') {
        min-height: 750px;
        padding: 90px 15px;
    }

    .slide-box-text {
        max-width: 490px;
        margin: 0 auto 23px;
    }

    h1 {
        margin: 0 0 .25em;
        font-weight: $light-font;
        font-family: $base-font-gravesend;
        font-weight: 300;
        line-height: calc(60/50);
        text-transform: uppercase;
        letter-spacing: .04em;
        color: $olive-100;
        font-size: calc(30px + (50 - 30) * ((100vw - 320px) / (1366 - 320)));

        @include media('≥widescreen-x') {
            font-size: 50px;
        }

        mark {
            background: none;
            color: $white;
            font-weight: $thin;
            font-family: $base-font-gravesend;
        }
    }

    p {
        margin: 0;
    }
}

.swiper-slide-active {
    .wrap-text {
        animation: top_in 1.5s ease both;
    }
}

.swiper-slide-prev,
.swiper-slide-next {
    .wrap-text {
        animation: top_out 1s ease both;
    }
}

.carousel-images {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 1;

    .images-slide {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        opacity: 0;
        z-index: 1;
        transition: 1s opacity;

        &:first-child {
            position: relative;
        }

        &.swiper-slide-active {
            z-index: 2 !important;
            opacity: 1;
        }
    }

    img {
        width: 100%;
    }
}