.section-form {
	position: relative;
	padding: 50px 0 80px;
	background: linear-gradient(#efebeb, #fff);
	z-index: 1;

	@include media('≥tablet') {
		padding: 77px 0 135px;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: -40px;
		right: -40px;
		bottom: 0;
		background: url('../images/crve-01.png') no-repeat center;
		z-index: -1;
	}

	.container {
		max-width: 1000px;
	}

	.form-grid {

		@include media('≥tablet') {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
		}

		@include media('≥widescreen') {
			grid-gap: 80px;
		}

		.col {
			margin-bottom: 30px;

			@include media('≥tablet') {
				margin-bottom: 0;
			}
		}
	}

	form {
		max-width: 945px;
		margin: 0 auto;

		input,
		textarea {
			width: 100%;
		}

		label {
			display: block;

			margin-bottom: 12px;

			strong {
				display: block;
			}
		}
	}

	.input-error {
		border: 2px solid crimson !important;
	}

	h3 {
		margin-bottom: 1.1em;
		font-size: 24px;
		color: $gray-900;
		text-transform: uppercase;

		@include media('≥tablet') {
			font-size: 33px;
		}
	}

	h4 {
		margin: 0 0 1.7em;
		letter-spacing: 0.2em;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: $medium;
		color: #8a887a;
		font-family: $base-font-gravesend;
	}

	.heading {
		margin: 0 0 2.9em;
		max-width: 680px;
		letter-spacing: 0.07em;

		h3 {
			margin-bottom: 0.9em;
		}

		strong {
			font-weight: $medium;
		}
	}
}

.form-project {
	h4 {
		margin-bottom: 1em;

		@include media('≥tablet') {
			margin-bottom: 3.1em;
		}
	}

	.iti {
		display: flex;
		position: relative;

		&__flag-container {
			width: 148px;
			position: relative;
			border-radius: $input-border-radius;
			background: #ebebeb;
			color: $gray-900;
			font-family: $base-font-gravesend;
			text-transform: uppercase;
			font-weight: $light-font;
			margin-right: 12px;
		}

		&__selected-flag {
			padding: $input-padding;
		}

		.js-intl-tel-input {
			padding: $input-padding;
		}
	}

	.iti__arrow {
		border: 0;
		background-image: url('../images/down.png');
		background-size: contain;
		width: 24px;
		height: 11px;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		margin: 0;
	}

	.btn {
		border-radius: 0;
	}

	[for="comments"] {
		margin-top: 35px;
		margin-bottom: 68px;
	}
}

.iti__country-list {
	border-radius: $input-border-radius;
	background: #ebebeb;
	color: $gray-900;
	font-family: $base-font-gravesend;
	text-transform: uppercase;
	font-weight: $light-font;
	border: 0;
	max-width: 400px;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 10px;
		height: 8px;
		background: transparent;
		border-radius: calc(5px / 2);
		margin: 0 6px 0 0;
	}

	&::-webkit-scrollbar-track {
		border-radius: calc(5px / 2);
	}

	&::-webkit-scrollbar-thumb {
		width: 10px;
		height: 40px;
		background: $olive;
		border-radius: calc(5px / 2);
	}
}

.iti__country {
	padding: 10px 20px;

	&.iti__highlight {
		background: $olive;

		.iti__dial-code,
		.iti__country-name {
			color: $white;
		}

		.iti__dial-code {
			font-weight: $medium;
		}
	}
}

.contacts-row {
	margin-bottom: 54px;
}

.columns-contacts {
	font-size: 16px;
	line-height: calc(27 / 16);
	color: $black;

	@include media('≥tablet') {
		display: flex;
		flex-wrap: wrap;
	}

	&-col {
		margin-bottom: 25px;

		@include media('≥tablet') {
			width: 50%;
			padding-right: 15px;
		}

		@include media('≥desktop') {
			width: 26%;
			margin-bottom: 0;
		}

		&:nth-child(2) {
			@include media('≥desktop') {
				width: 30%;
			}
		}

		&:last-child {
			width: 100%;
			padding-right: 0;

			@include media('≥desktop') {
				width: auto;
			}
		}
	}

	.list-contact {
		a {
			color: inherit;
			transition: .7s all;

			&:hover {
				opacity: .8;
			}
		}
	}
}

.hours {
	display: grid;
	grid-template-columns: 115px 1fr;

	dt {
		font-weight: $normal;
	}

	dd {
		margin-left: 0;
	}
}

.addresse {
	margin: 0;
	padding: 0;
	font-style: normal;
}

.pac-container {
    background-color: #ebebeb;
    font-family: inherit;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    border-radius: 12px;

	.pac-item {
		padding: 10px 20px;
		font-size: 14px;
		cursor: pointer;
		font-weight: 500;

		&:hover {
			background-color: $olive;
			color: #fff;
		}

		* {
			color: inherit;
		}
	}

	&::-webkit-scrollbar {
		width: 10px;
		height: 8px;
		background: transparent;
		border-radius: calc(5px / 2);
		margin: 0 6px 0 0;
	}

	&::-webkit-scrollbar-track {
		border-radius: calc(5px / 2);
	}

	&::-webkit-scrollbar-thumb {
		width: 10px;
		height: 40px;
		background: $olive;
		border-radius: calc(5px / 2);
	}
}