.section-text-block {
    padding: 100px 0;

    @include media('≥desktop') {
        padding: 170px 0 135px;
    }

    .container {
        max-width: 710px;
    }

    h3 {
        margin: 0 0 0.75em;
        color: $gray-900;
    }

    .text-box {
        max-width: 580px;
    }
}