.section-testimonial {
    background-size: cover;
    background-position: 50% 0;
    padding-bottom: 50vw;
    background-repeat: no-repeat;

    @include media('≥widescreen-x') {
        padding-bottom: 707px;
    }


    .container {
        position: relative;
        max-width: 874px;

        &:after {
            position: absolute;
            bottom: -50px;
            top: 0;
            left: 50%;
            width: calc(100vw - 30px);
            transform: translateX(-50%);
            content: '';
            background: linear-gradient(to bottom, rgba(239, 235, 235, 1) 80%, rgba(239, 235, 235, 0) 100%);

            @include media('≥tablet') {
                width: calc(100vw - 80px);
                background: linear-gradient(to bottom, rgba(239, 235, 235, 1) 50%, rgba(239, 235, 235, 0) 100%);
            }
        }
    }

    .blockquote {
        position: relative;
        z-index: 1;

        &-title {
            margin-bottom: 0;
            color: $gray-900;
            line-height: calc(34/25);

            @include media('≥desktop') {
                font-size: 25px;
            }
        }

        &-cite {
            position: relative;
            z-index: 1;
            margin: 26px 0 0 auto;
            padding: 57px 0 22px 27px;
            width: 80%;

            @include media('≥tablet') {
                margin: 26px 0 0 20.5%;
            }
        }

        &-name {
            color: #21211f;
        }

        &-pocition {
            color: #958e73;
        }
    }
}