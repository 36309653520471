.section-projects {
    position: relative;
    padding-top: calc(50px + (142 - 50) * ((100vw - 320px) / (1366 - 320)));
    padding-bottom: 1px;

    @include media('≥widescreen-x') {
        padding-top: 142px;
    }

    .container {
		padding: 0 28px;
        max-width: none;

		@include media('>=widescreen') {
       		padding: 0 116px;
		}
    }

    &-add {
        @include media('≥widescreen-x') {
            padding-top: 80px;
        }
    }
}

.list-projects {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
        width: 100%;
        margin-bottom: 30px;

        @include media('≥tablet') {
            width: 46.4%;
        }

        @include media('≥desktop') {
            margin-bottom: 137px;
            margin-bottom: 80px;
        }
    }
}

.project {
	position: relative;

	&:hover {
		img {
			transform: scale(1.1);
		}
	}

    &-visual {
        position: relative;
        display: block;
        padding-top: 64.3%;
        overflow: hidden;
        margin-bottom: 28px;

        img {
            position: absolute;
            inset: 0;
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
			transition: .7s transform;
        }
    }

    &-body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &-text {
        width: calc(100% - 65px);
        padding-right: 10px;

        @include media('≥desktop') {
            width: calc(100% - 90px);
        }
    }

    h4 {
        margin: 0 0 .5em;
        font-size: calc(20px + (25 - 20) * ((100vw - 320px) / (1366 - 320)));
        line-height: calc(30/25);
        text-transform: uppercase;
        color: $gray-900;
        font-weight: $light-font;
        letter-spacing: 0.04em;
		transition: .7s color;

        @include media('≥widescreen-x') {
            font-size: 25px;
        }

		&:hover {
			color: #7a7662;
		}

        a {
            color: inherit;
        }
    }

    &-location {
        color: #7a7662;
        display: block;
        text-transform: uppercase;
        font-weight: $medium;
        font-size: 12px;
        line-height: calc(20/12);
        letter-spacing: 0.2em;
    }
}

.link-box {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 65px;
    min-width: 65px;
    border-left: 2px solid #bebbb0;
    padding: 10px 0 10px 10px;
    min-height: 50px;

    @include media('≥desktop') {
        width: 90px;
        min-width: 90px;
        min-height: 78px;
    }
}

.link {
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 15px 0 0;
    font-size: 10px;
    line-height: calc(18/13);
    font-weight: $medium;
    color: #878474;
    letter-spacing: 0.2em;
    font-family: $base-font-gravesend;
	transition: .7s all;

    @include media('≥desktop') {
        font-size: 13px;
        padding: 0 24px 0 0;
    }

    &:after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 8px;
        height: 18px;
        transform: translateY(-50%);
        content: '';
        background: url('../images/arrow-next-olive.png') no-repeat;
        background-size: contain;
        transition: transform .7s;
    }

    &:hover {
        &:after {
            transform: translate(-7px, -50%);
        }
    }
}

.best-position {
    position: relative;
    z-index: 2;
    padding: 10px 15px;
    background: $white;
    box-shadow: 0 0 43px rgba(#191818, 0.09);
    text-align: center;
    width: 90%;
    max-width: 765px;
    margin: 0 auto;

    @include media('≥tablet') {
        display: flex;
        align-items: center;
        text-align: left;
        padding: 22px 28px;
    }

    &-icon {
        width: 68px;
        min-width: 68px;
        margin: 0 auto 15px;

        @include media('≥tablet') {
            margin: 0 22px 0 0;
        }
    }

    &-text {
        display: block;
        color: #25292b;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: $medium;
    }

    h5 {
        margin: 0 0 0.1em;
        color: #897f6f;
        font-weight: $medium;
        font-size: 18px;
        line-height: calc(24/22);
        letter-spacing: 0.04em;
        text-transform: uppercase;

        @include media('≥tablet') {
            font-size: 22px;
        }

        a {
            color: inherit;
        }
    }
}

.project-full {
    position: relative;
    padding: 30px 0 0;
    margin-bottom: 50px;

    @include media('≥tablet') {
        margin-bottom: 100px;
    }

    .py-117 & {
        margin-left: calc((20px + (117 - 20) * ((100vw - 320px) / (1366 - 320))) * -1);
        margin-right: calc((20px + (117 - 20) * ((100vw - 320px) / (1366 - 320))) * -1);

        @include media('≥widescreen-x') {
            margin-left: -117px;
            margin-right: -117px;
        }
    }

    &-visual {
        margin-top: -182px;
    }

    img {
        max-width: none;
        width: 100%;
        display: block;
    }

    &-body {
        position: relative;
        z-index: 1;
        width: 80%;
        max-width: 765px;
        margin: 0 auto 140px;
        text-align: center;

        @include media('≥desktop') {
            margin-bottom: 52px;
        }
    }

    h3 {
        margin: 0 0 .6em;
        font-size: calc(24px + (36 - 24) * ((100vw - 320px) / (1366 - 320)));
        line-height: calc(40/36);
        text-transform: uppercase;
        color: $gray-900;
        font-weight: $light-font;
        letter-spacing: 0.04em;

        @include media('≥widescreen-x') {
            font-size: 36px;
        }

        a {
            color: inherit;
        }
    }

    h4 {
        margin: 0 0 0.5rem;
        font-size: calc(20px + (25 - 20) * ((100vw - 320px) / (1366 - 320)));
        line-height: calc(30/25);
        text-transform: uppercase;
        color: $gray-900;
        font-weight: $light-font;
        letter-spacing: 0.04em;

        @include media('≥widescreen-x') {
            font-size: 25px;
        }

        a {
            color: inherit;
        }
    }

    &-location {
        color: #7a7662;
        display: block;
        text-transform: uppercase;
        font-weight: $medium;
        font-size: 12px;
        line-height: calc(20/12);
        letter-spacing: 0.2em;
    }

	.best-position {
		margin-bottom: 50px;
		margin-top: -50px;

		@include media('>=tablet') {
			margin-bottom: 50px;
			margin-top: -80px;
		}

		@include media('>=1200px') {
			margin-bottom: 30px;
			margin-top: -50px;
		}
    }

    .link-box {
        margin-top: 30px;
		margin-left: auto;
		margin-right: 28px;

		@include media('>=widescreen') {
			margin-right: 116px;
		}
    }

    &-add {
        padding-bottom: 60px;

        @include media('≥tablet') {
            padding-bottom: 103px;
            margin-bottom: 90px;
            padding-top: 45px;
        }
    }
}