.section-social {
	.container {
		max-width: none;
		padding-right: 25px;
		padding-left: 25px;

		@include media('>=widescreen') {
			padding-left: 128px;
		}
	}

	.heading-holder {
		padding-top: 40px;
		padding-bottom: 35px;
		background-color: #e1dede;

		@include media('>=tablet') {
			padding-top: 80px;
			padding-bottom: 35px;
		}

		h3 {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.inner {
		position: relative;
		background-color: #e1dede;
		overflow: hidden;
		padding-bottom: 40px;

		@include media('≥desktop') {
			padding-bottom: 60px;
		}
	}

    .horizon-line {
        &:after {
            top: 20px;
        }
    }

	.carousel-holder {
		margin: 0 -50px;

		@include media('>=desktop') {
			margin: 0 -100px;
		}

		.swiper-pagination {
			transform: none;
			position: static;
			margin-top: 47px;
			color: #88765a;
		}
	}

	.swiper-slide {
		-webkit-backface-visibility: hidden; 
		-webkit-transform: translate3d(0, 0, 0);
		overflow: hidden;

		a {
			display: block;
			background-color: $blue;
		}
	}

	.swiper-wrapper {
		position: relative;
		align-items: flex-end;
	}

	.swiper-slide {
		display: flex;
		transition: .4s all;

		&.swiper-slide-active {
			margin-right: 40px !important;

			@include media('>=desktop') {
				margin-right: 84px !important;
			}
		}

		&.swiper-slide-prev {
			margin-left: 40px !important;

			@include media('>=desktop') {
				margin-left: 84px !important;
			}
		}

		a {
			width: 100%;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: .5s transform;

			&:hover {
				transform: scale(1.02);
			}
		}
	}

	.slide-size-1 {
		width: calc(480px * .5 ) !important;
		height: calc(405px * .5 ) !important;

		@include media('>=tablet') {
			width: calc(480px * .7 ) !important;
			height: calc(405px * .7 ) !important;
		}

		@include media('>=desktop') {
			width: 480px !important;
			height: 405px !important;
		}
	}
	
	.slide-size-2 {
		width: calc(240px * .5) !important;
		height: calc(225px * .5) !important;
		margin-bottom: 30px;

		@include media('>=tablet') {
			width: calc(240px * .7) !important;
			height: calc(225px * .7) !important;
		}

		@include media('>=desktop') {
			width: 240px !important;
			height: 225px !important;
			margin-bottom: 67px;
		}

		img {
			width: 100%;
			height: 225;
		}
	}
	
	.slide-size-3 {
		width: calc(227px * .5) !important;
		height: calc(320px * .5) !important;
		margin-bottom: 50px;

		@include media('>=tablet') {
			width: calc(227px * .7) !important;
			height: calc(320px * .7) !important;
		}

		@include media('>=desktop') {
			width: 227px !important;
			height: 320px !important;
			margin-bottom: 135px;
		}
	}
	
	.slide-size-4 {
		width: calc(400px * .5) !important;
		height: calc(355px * .5) !important;
		margin-bottom: 10px;

		@include media('>=tablet') {
			width: calc(400px * .7) !important;
			height: calc(355px * .7) !important;
		}

		@include media('>=desktop') {
			width: 400px !important;
			height: 355px !important;
			margin-bottom: 10px;
		}
	}
}