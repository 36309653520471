.swiper-pagination,
.swiper-pagination-bullets.swiper-pagination-horizontal {
	all: unset;
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: auto;
	bottom: 55px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10;
	color: $white;

	@include media('≥tablet') {
		top: auto;
		bottom: 117px;
	}

	button {
		color: currentColor;
	}

	&.center {
		position: static;
		transform: none;
		margin-top: 40px;
	}

	&.dark {
		color: #7e7b6b;

		.swiper-pagination-bullet {
			button {
				background: radial-gradient(circle at center, #88765a 0%, #88765a 3px, rgba($white, 0) 3px, rgba($white, 0) 100%);
			}
		}
	}

	.swiper-pagination-bullet {
		all: unset;
		margin: 0 11px;
		transform: rotate(-90deg) scaleY(-1);

		button {
			@include size (18px);
			display: block;
			font-size: 0;
			border-radius: 50%;
			border: 0;
			padding: 0;
			color: currentColor;
			background: radial-gradient(circle at center, $gray-600 0%, $gray-600 3px, rgba($white, 0) 3px, rgba($white, 0) 100%);

			@include media('≥tablet') {
				@include size (22px);
			}

			svg {
				width: 100%;
				height: 100%;
				display: block;
				opacity: 0;
				transition: opacity 0.3s ease;
			}
		}

		&.swiper-pagination-bullet-active {
			button {
				svg {
					opacity: 1;
				}

				circle {
					animation: sliderProgress 5s both linear;

					.js-desc-slider.paused & {
						animation-play-state: paused;
					}

					.js-desc-slider:not(.paused) & {
						animation-play-state: running;
					}
				}
			}
		}
	}
}

@keyframes sliderProgress {
	0% {
		stroke-dashoffset: -169.56;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

.left-slide,
.right-slide {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 0;


	@include media('>=desktop') {
		width: 30%;
	}
}

.right-slide {
	left: auto;
	right: 0;
}

.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	margin-top: 0;
	cursor: none !important;

	&:after {
		display: none;
	}
}

.has-custom-cursor a,
.has-custom-cursor button,
.has-custom-cursor .js-hovered-item {
	cursor: none;
}