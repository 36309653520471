.section-testimonials-group {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: calc(40px + (134 - 40) * ((100vw - 320px) / (1366 - 320)));
    padding-bottom: calc(40px + (144 - 40) * ((100vw - 320px) / (1366 - 320)));

    @include media('≥tablet') {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include media('≥widescreen-x') {
        padding-top: 134px;
        padding-bottom: 144px;
    }

    .container {
        padding: 0;
    }
}

.list-testimonials {
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
        +li {
            margin-top: 100px;

            @include media('≥tablet') {
                margin-top: 150px;
            }

            @include media('≥desktop') {
                margin-top: 200px;
            }
        }
    }
}

.testimonial-el {
    padding: 0 20px;
    margin: 0 auto;
    max-width: 960px;
    width: 100%;

    @include media('≥tablet') {
        padding: 0 35px;
    }

    blockquote {
        padding: 0;
        margin: 0;
        text-align: center;
        border: 0;


        p {
            margin: 0;
        }
    }

    &-title {
        display: block;
        margin: 0 0 1.8em;
        font-size: 18px;
        line-height: calc(33/25);
        font-weight: $light-font;
        font-family: $base-font-gravesend;
        text-transform: uppercase;
        color: #6e6d69;

        @include media('≥desktop') {
            font-size: 25px;
        }
    }

    &-text {
        display: block;
        margin: 0 auto 2.3em;
        color: $gray-900;
        max-width: 770px;
        font-size: 14px;

        @include media('≥desktop') {
            font-size: 16px;
        }
    }

    cite {
        display: block;
        margin: 0 0 27px;
        font-style: normal;
        text-align: left;
    }

    &-name {
        display: block;
        margin: 0 0 4px;
        font-weight: $medium;
        color: #21211f;
        font-size: 14px;
        text-transform: uppercase;

        @include media('≥tablet') {
            font-size: 18px;
        }
    }

    &-position {
        display: block;
        color: #958e73;
        font-size: 12px;
        text-transform: uppercase;

        @include media('≥tablet') {
            font-size: 14px;
        }
    }

    &-footer {
        border-left: 2px solid $olive;
        padding-left: 10px;
        padding-top: 30px;

        @include media('≥tablet') {
            padding-left: 13px;
            margin: 0 0 0 auto;
            width: 50.4%;
        }
    }
}