.line-hail {
	transition: opacity 0.5s ease-in-out;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	width: 2px;
	height: 80px;
	background-color: #d2bd9c;
	pointer-events: none;
	overflow: hidden;

	@include media('≥tablet') {
		height: 134px;
	}
}

.hide-scroll-indicator .line-hail {
	opacity: 0;
}

.line-hail:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 50%;
	background: #fff;
	animation: 2.5s slideLine infinite linear;
}

@keyframes slideLine {
	0% {
		transform: translateY(-300%);
	}

	50% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(300%);
	}
}