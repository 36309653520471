.section-testimonial-full {
    margin: 0 -15px;
    padding: 50px 0 30px;
    text-align: center;
    background-size: cover;
    background-position: 50% 50%;

    @include media('≥tablet') {
        margin: 0 -40px;
        padding: 87px 0 40px;
    }

    .container {
        max-width: 1068px;
    }

    h3 {
        margin: 0 0 4.3em;
        font-weight: $medium;
        letter-spacing: 0.68em;
        font-size: 13px;
        line-height: 1.2;
        text-transform: uppercase;
        font-family: $base-font-gravesend;
        color: #d7d1bf;
    }
}

.blockquote {
    border: 0;
    padding: 0;
    margin: 0;

    &-title {
        display: block;
        margin: 0 0 1.5em;
        font-size: 18px;
        line-height: calc(38/22);
        color: #fbfaf8;
        font-family: $base-font-gravesend;
        font-weight: $light-font;
        text-transform: uppercase;
        letter-spacing: 0.04em;

        @include media('≥tablet') {
            font-size: 22px;
        }
    }

    &-text {
        color: #fcfbfa;
        font-size: 16px;
        line-height: calc(25/16);
        font-weight: $normal;
        font-family: $base-font;
        letter-spacing: 0.025em;
    }

    &-cite {
        display: block;
        padding: 24px 0 32px 40px;
        margin: 50px 0 0;
        width: 100%;
        font-family: $base-font-gravesend;
        text-transform: uppercase;
        font-weight: $medium;
        text-align: left;
        font-style: normal;
        border-left: 2px solid $olive;

        @include media('≥tablet') {
            margin: 73px 0 0 auto;
            max-width: 50%;
        }
    }

    &-name {
        margin: 0 0 0.5em;
        display: block;
        color: #faf9f7;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: 0.03em;
    }

    &-pocition {
        display: block;
        color: #bcb7a2;
        letter-spacing: 0.1em;
        font-size: 14px;
        line-height: 1.2;
    }
}