.section-projects-gallery {
	.swiper-pagination {
		transform: none;
		position: static;

		@include media('>=desktop') {
			margin-top: 20px;
		}
	}

	.container {
		max-width: none;
		padding-right: 25px;
		padding-left: 25px;

		@include media('>=widescreen') {
			padding-left: 128px;
		}
	}

	.heading-holder {
		padding-top: 40px;
		padding-bottom: 35px;
		background-color: #e7e5e5;


		@include media('>=tablet') {
			padding-top: 80px;
			padding-bottom: 35px;
		}

		@include media('>=widescreen') {
			padding-top: 150px;
			padding-bottom: 105px;
		}

		h3 {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.inner {
		position: relative;
		background-color: #e7e5e5;
		padding-bottom: 40px;

		@include media('≥desktop') {
			padding-bottom: 85px;
		}
	}

	.carousel-holder {
		margin: 0 -40vw;
		overflow: hidden;
		position: relative;
	}

	.swiper-slide {
		transform: scale(0.84);
		transition: .4s all;
  
		&.swiper-slide-active{
		  transform: scale(1);
		}
	}

	.left-slide,
	.right-slide {
		width: 15vw;
		height: 32vw;
	}
	
	.left-slide {
		left: 38vw;
	}
	
	.right-slide {
		right: 38vw;
	}
}

.card-gall {
	text-align: center;

	.img {
		position: relative;
		padding-top: 58%;
		overflow: hidden;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: .5s transform;

			&:hover {
				transform: scale(1.02);
			}
		}
	}

	.card-content {
		padding: 10px 0 0 0;
		letter-spacing: .07em;

		@include media('>=tablet') {
			padding: 15px 0 0 0;
		}

		@include media('>=desktop') {
			padding: 28px 0 0 0;
		}

		h5 {
			font-size: 14px;
			font-weight: 300;
			color: #282623;
			text-transform: uppercase;
			margin-bottom: 0;

			@include media('>=phone') {
				font-size: 18px;
			}

			@include media('>=tablet') {
				font-size: 20px;
			}

			@include media('>=desktop') {
				font-size: 25px;
			}
		}
	
		address {
			font-family: $base-font-gravesend;
			letter-spacing: .25em;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 12px;
			font-style: normal;
			color: #7a7662;
	
			p {
				margin-bottom: 0;
			}
		}
	}

	.card-content {
		opacity: 0;
		transition: .4s all;
	}

	.swiper-slide-active & {
		.card-content {
			opacity: 1;
		}
	}
}