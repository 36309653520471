.section-specialists {
    position: relative;
    padding: 50px 0 0;

    @include media('≥tablet') {
        padding-top: 80px;
    }

    @include media('≥desktop') {
        padding-top: 130px;
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
        height: 313px;
        content: '';
        background: url('../images/bg-specialist.png') no-repeat;
        background-size: contain;
        background-position: 0 0;
        pointer-events: none;

        @include media('≥tablet') {
            width: 247px;
            height: 313px;
        }
    }

    .container {
        max-width: 984px;
    }

    .section-heading {
        margin: 0 0 121px;
        max-width: 610px;
        color: #6a685c;

        &-text {
            max-width: 390px;
        }

        .sub-title {
            color: #34332b;
        }

        h3 {
            margin-bottom: 0.7em;
            color: #34332b;
        }
    }

    .horizon-line {
        &:after {
            @include media('≥widescreen-x') {
                top: 18px;
                right: calc(100% + 96px);
            }
        }
    }
}

.list-specialists {
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
        &:nth-child(even) {
            .specialist {
                @include media('≥tablet') {
                    text-align: right;
                    flex-direction: row-reverse;
                }

                &-body {
                    @include media('≥tablet') {
                        padding-left: 13%;
                        padding-right: 3%;
                    }
                }
            }
        }
    }
}

.specialist {

    @include media('≥tablet') {
        display: flex;
        align-items: center;
    }

    &-visual {
        position: relative;
        padding-top: 70%;

        @include media('≥tablet') {
            padding-top: 39%;
            width: 50%;
        }

        img {
            position: absolute;
            inset: 0;
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    &-body {
        padding: 15px 15px 30px;

        @include media('≥tablet') {
            width: 50%;
            padding: 30px 13% 30px 3%;
        }
    }

    h4 {
        margin: 0 0 1em;
        text-transform: uppercase;
        font-weight: $light-font;
        font-family: $base-font-gravesend;
        font-size: 24px;
        line-height: calc(43/33);
        color: #282623;

        @include media('≥tablet') {
            font-size: 28px;
        }

        @include media('≥desktop') {
            font-size: 33px;
        }
    }

    p {
        &:last-child {
            margin: 0;
        }
    }
}