// Typography

@include title-size('≥');

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
    font-family: $base-font-gravesend;
    color: $headings-color;
    font-weight: bold;
    margin: 0 0 10px;

    @include media('≥tablet') {
        margin-bottom: 20px;
    }
}

h3,
.h3 {
    margin: 0 0 2em;
    text-transform: uppercase;
    font-size: 18px;
    line-height: calc(43/40);
    letter-spacing: 0.047em;
    color: $olive-200;
    font-weight: $light-font;

	@include media('>=576px') {
		font-size: calc(26px + (40 - 26) * ((100vw - 320px) / (1366 - 320)));
	}

    @include media('≥widescreen-x') {
        font-size: 40px;
    }

    mark {
        background: none;
        display: block;
        color: $gray-800;
        font-weight: $thin;
		letter-spacing: 0.066em;
    }
}

p {
    margin: 0 0 20px;
}

a {
    color: $base-link-color;
    text-decoration: $text-decoration;

    &:hover,
    &:focus {
        color: $base-link-hover-color;
        text-decoration: $text-decoration-hover;
    }
}

a[href*="mailto:"] {
    word-wrap: break-word;
}

.sub-title {
	display: block;
	font-weight: $medium;
	font-family: $base-font-gravesend;
	text-transform: uppercase;
	font-size: 10px;
	line-height: 1.2;
	color: $gray-800;
	letter-spacing: 0.7em;

	@include media("≥tablet") {
		font-size: 13px;
	}
}

.section-heading {
    letter-spacing: 0.042em;
	
	h3 {

		@include media('>=desktop') {
			margin-bottom: 1em;
		}

		@include media('>=widescreen') {
			margin-bottom: 2.7em;
		}
	}

	.sub-title {
		margin-bottom: 1.7em;

		@include media("≥desktop") {
			margin-bottom: 3.7em;
		}
	}
}