/* Gravesend Fonts */

@include font-face(gravesend, gravesend-sans-fine, 100, normal);
@include font-face(gravesend, gravesend-sans-light, 300, normal);
@include font-face(gravesend, gravesend-sans-medium, 500, normal);
@include font-face(gravesend, gravesend-sans-bold, 700, normal);

// -----------------------------------------------------------------------------

/* Avenir Fonts  */

@font-face {
    font-family: 'avenir';
    src: url('../fonts/avenir-medium-09-webfont.woff2') format('woff2'),
        url('../fonts/avenir-medium-09-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src: url('../fonts/avenir-book-01-webfont.woff2') format('woff2'),
        url('../fonts/avenir-book-01-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src: url('../fonts/avenir-light-07-webfont.woff2') format('woff2'),
        url('../fonts/avenir-light-07-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}