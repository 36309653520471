.section-testimonials {
    position: relative;
	padding: 30px 0;

    @include media('≥tablet') {
        padding-top: 80px;
		padding-bottom: 80px;
    }

    .inner {
        background-color: #efebeb;
    }

    .container {
        max-width: none;
    }

    &-visual {
        position: absolute;
        height: 55%;
        top: 0;
        left: -40px;
        right: -40px;
        z-index: 1;
        background-attachment: fixed;
        background-size: cover;
        background-position: 50% 50%;

        // @include media('>=phone') {
        //     height: 250px;
        // }

        @include media('>=desktop') {
            height: 329px;
        }

        img {
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    .container {
        padding: 0;
    }

    .section-heading {
        padding: 0 40px;

        @include media('≥desktop') {
            padding: 0 174px;
        }
    }

    .sub-title {
        color: #d9cfae;
    }

    &-hold {
        position: relative;
        z-index: 1;
    }

    .carousel-holder {
        position: relative;
    }

    .swiper-pagination,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        position: static;
        transform: none;
        margin-top: 30px;

        @include media('>=desktop') {
            margin-top: 57px;
        }
    }
}

.testimonial {
    padding: 0 20px;
    margin: 0 auto;
    max-width: 970px;

    @include media('≥tablet') {
        padding: 0 35px;
        width: 100%;
    }

    blockquote {
        padding: 0;
        margin: 0;
        font-size: 18px;
        line-height: calc(33/25);
        font-weight: $light-font;
        font-family: $base-font-gravesend;
        color: #f9f8f6;
        text-transform: uppercase;
        text-align: center;
        border: 0;

        @include media('≥phone') {
            font-size: 20px;
        }

        @include media('≥desktop') {
            font-size: 25px;
        }

        p {
            margin: 0;
        }
    }

    cite {
        display: block;
        margin: 0 0 27px;
        font-style: normal;
        text-align: left;
    }

    &-name {
        display: block;
        margin: 0 0 4px;
        font-weight: $medium;
        color: #21211f;
        font-size: 12px;
        text-transform: uppercase;

        @include media('≥phone') {
            margin: 0 0 8px;
            font-size: 16px;
        }
    }

    &-position {
        display: block;
        color: #958e73;
        font-size: 12px;
        text-transform: uppercase;

        @include media('≥phone') {
            font-size: 16px;
        }
    }

    &-footer {
        border-left: 2px solid $olive;
        padding-left: 15px;
        margin-top: 10vw;
        padding-top: 46px;

        @include media('≥phone') {
            padding-left: 25px;
            margin-left: 30px;
        }

        @include media('>=tablet') {
            max-width: 740px;
            margin-left: auto;
            margin-top: 60px;
        }
    }

    &-buttons {
		background: $olive;
        padding: 3px;

		@include media('>=576px') {
			display: inline-grid;
			grid-template-columns: 1fr 1fr;
		}

        @include media('≥desktop') {
			grid-template-columns: 260px 330px;
        }

        .btn {
            display: flex;
            align-items: center;
            text-align: left;
            padding: 13px 20px;
            flex-grow: 1;
			min-width: auto;

            @include media('≥desktop') {
                padding: 13px 67px 13px 20px;
            }

			&:last-child {
				background-color: #efebeb;

				&:hover {
					background-color: #9e9b88;
				}
			}
        }
    }
}


.swiper-slide-active {

    blockquote,
    .testimonial-footer {
        animation: fade_in 0.3s ease both;
    }
}

.swiper-slide-prev,
.swiper-slide-next {

    blockquote,
    .testimonial-footer {
        animation: fade_out 0.3s ease both;
    }
}