.section-support {
    text-align: center;
    position: relative;
    padding: calc(50px + (132 - 50) * ((100vw - 320px) / (1366 - 320))) 0 calc(50px + (70 - 50) * ((100vw - 320px) / (1366 - 320)));

    @include media('≥widescreen-x') {
        padding: 132px 0 70px;
    }

    &:after {
        position: absolute;
        inset: 0 -15px;
        content: '';
        background: url('../images/bg-support.png') no-repeat;
        background-position: 100% 100%;

        @include media('≥tablet') {
            inset: 0 -40px;
        }
    }

    h3 {
        max-width: 840px;
        margin: 0 auto 1.4em;
        color: $gray-900;
        font-family: $base-font-gravesend;
    }

    .list-support {
        max-width: 900px;
        margin: 0 auto;
    }

    &-text {
        padding: 26px 0;
        max-width: 778px;
        margin: 0 auto;
    }

    &-text-bar {
        max-width: 430px;
        margin: 0 auto;
    }

    b {
        font-weight: $medium;
    }
}

.list-support {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    >li {
        padding: 0 15px 30px;
        width: 100%;

        @include media('≥tablet') {
            width: calc(100% / 3);
        }
    }
}

.support {
    text-align: center;

    &-icon {
        height: 72px;
        margin: 0 0 7px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    h4 {
        margin: 0;
        text-transform: uppercase;
        color: #928b7b;
        font-weight: $medium;
        letter-spacing: 0.04em;
        font-size: 16px;
        letter-spacing: calc(20/16);
        font-family: $base-font-gravesend;
    }
}