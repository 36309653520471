.nav-active,
.megamenu-opened {
	touch-action: none;
	-webkit-overflow-scrolling: none;
	overflow: hidden;
	overscroll-behavior: none;
}

.megamenu {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	text-transform: uppercase;
	pointer-events: none;

	>* {
		pointer-events: auto;
	}

	&:before{
		@include animate (opacity visibility);
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.55);
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition-delay: calc($animation-delay-menu / 2);
		pointer-events: none;

		.nav-active & {
			opacity: 1;
			visibility: visible;
			transition-delay: 0s;
		}
	}

	a:not([class]) {
		@include animate (color text-shadow);
		color: $gray-400;

		&:hover {
			color: #fafcfd;
			opacity: 1;
			text-shadow: 1px 1px 0 currentColor, 0px 1px 0 currentColor, 1px 0 0 currentColor;
		}
	}

	.wrap-col {
		@include animate (transform, $animation-duration-holder $animation-delay-menu);
		text-align: left;
		min-height: 100%;
		position: relative;
		z-index: 10;
		height: 100%;
		transform: translateX(100vw);
		margin-left: auto;
		padding: 85px $gutter;
		background: rgba(33, 43, 60, 0.5);
		backdrop-filter: blur(150px) saturate(1.8);
		-webkit-backdrop-filter: blur(150px) saturate(1.8);

		@media screen and (max-height: 700px) {
			padding-bottom: 20px !important;
		}


		@include media('≥tablet') {
			width: 50%;
			min-width: 500px;
			padding: 80px 46px;
		}

		@include media('≥desktop') {
			width: 26%;

		}

		@include media('≥widescreen') {
			padding: 100px 45px 80px;
		}

		.nav-active & {
			pointer-events: auto;
			transform: translateX(0%);
			transition-delay: 0s;
		}

		.second-level-active & {
			max-height: 100%;
			overflow: hidden;
		}

		.second-level-hide & {
			transition-delay: calc($animation-delay-menu / 2);
		}

		.col-menu {
			display: flex;
			flex-direction: column;
			position: relative;
			overflow: hidden auto;
			height: 100%;
		}
	}

	.wrap-nav {
		margin: 90px 0 auto 0;

		@include media('>=desktop') {
			margin: 200px 0 auto 0;
		}
	}
}

.wrap-nav>.sub-nav,
[id="nav"] {
	>ul {
		@extend %listreset;

		>li {
			>.js-anim-item {
				@include animate (opacity transform, $animation-duration-items);
				opacity: 0;
				transform: translateX(100%);
				transition-delay: calc(var(--item-index) * 50ms + $delay);

				.nav-active & {
					opacity: 1;
					transform: translateX(0%);
					transition-delay: calc(var(--item-index-reverse) * 50ms + $delay);
				}
			}

			.second-level-active & {
				$delay: 0s;
				pointer-events: none;

				>.js-anim-item {
					transition-delay: 0s;
					opacity: 0;
					transform: translateX(100%);
					transition-duration: 0.5s;
				}
			}
		}
	}
}

[id="nav"] {
	font-size: 16px;
	letter-spacing: 0.2em;

	@media screen and (max-height: 460px) {
		font-size: 14px;
	}

	>ul {
		>li {
			+li {
				margin-top: 15px;

				@include media('≥widescreen') {
					margin-top: 20px;
				}
			}
		}
	}
}

.sub-nav {
	@extend %listreset;
	margin: 50px 0 0;
	letter-spacing: 0.2em;
	font-size: 14px;

	@media screen and (max-height: 460px) {
		font-size: 12px;
	}

	@media screen and (max-height: 700px) {
		position: static !important;
	}

	@include media('≥tablet') {
		margin-top: 50px;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	li {
		+li {
			margin-top: 5px;

			@include media('≥widescreen') {
				margin-top: 14px;
			}
		}
	}

	a {
		&:hover {
			text-shadow: 1px 0 0 currentColor;
		}
	}
}