.section-video-portfolio {
	position: relative;
	padding-top: 25px;

	@include media('≥widescreen') {
		padding-top: 58px;
	}

	&::before {
		content: "";
		pointer-events: none;
		position: absolute;
		left: 0;
		top: 94%;
		width: 100%;
		height: 135vw;
		background-image: url('../images/crve.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 30% center;
		z-index: 0;
		box-shadow: inset 0px 100px 100px 20px #efebeb;
	}
	
	.best-position {
		margin-bottom: 50px;
		margin-top: -110px;

		@include media('>=tablet') {
			margin-bottom: 50px;
			margin-top: -80px;
		}

		@include media('>=1200px') {
			margin-bottom: 50px;
			margin-top: -110px;
		}
    }

    .inner {
        position: relative;
        z-index: 1;
        max-width: 908px;
        margin: 0 auto;

        .img {
            position: relative;
            padding-top: 64.5%;
        }

        img {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            top: 0;
            object-fit: cover;
            object-position: center;
        }

        .btn-video {
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: none;
            font-size: 0;
            line-height: 1;
        }

        .btn-play {
            padding: 0;
            width: 72px;
            height: 72px;
            transition: all $animation-speed;
        }
    }

}