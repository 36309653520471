// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site
// -----------------------------------------------------------------------------
[id="header"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 40px 0;
    z-index: 20;

    @include media('≥tablet') {
        padding: 79px 0 30px 0;
    }

    .container {
        padding: 0 15px;
        max-width: calc(100% - 80px);

        @include media('>=tablet') {
            padding: 0 40px;
        }
    }
}

.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.social-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 5px 15px;

    @include media('≥tablet') {
        gap: 10px 34px;
    }

    a {
        transition: opacity $animation-speed;

        &:hover {
            opacity: 0.7;
        }
    }
}

.logo {
    text-align: center;
    margin: 20px auto;
    width: 100%;

    @include media('≥tablet') {
        width: 259px;
        position: absolute;
        margin-top: 12px;
        margin-bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    a {
        display: inline-block;
        vertical-align: top;
        width: 150px;

        @include media('≥tablet') {
            width: 100%;
        }
    }
}

.nav-active {
    overflow: hidden;
}

button.nav-opener {
    position: relative;
    width: 41px;
    height: 21px;
    font-size: 0;
    line-height: 0;
    float: right;
    padding: 0;
    background: none;
    z-index: 101;

	&:hover {
		opacity: .7;
	}

    &:before,
    &:after,
    span {
        @include animate (all, .4s 1.1s);
        background: $olive-300;
        position: absolute;
        top: 50%;
        right: 0;
        width: 22px;
        height: 2px;
        transform: translateY(-50%);
        border-radius: 4px;
    }

    span {
        display: flex;
        justify-content: space-between;

        &:before,
        &:after {
            @include animate (all, .4s 1s);
            display: block;
            content: '';
            height: 2px;
            opacity: 0;
        }

        &:before {
            width: 50%;
            background: #fff;
        }

        &:after {
            width: 25%;
            background: #aea591;
        }
    }

    &:before,
    &:after {
        content: '';
    }

    &:before {
        width: 100%;
        top: 0;
        transform: translateY(0);
    }

    &:after {
        width: 30px;
        top: calc(100% - 2px);
        transform: translateY(0);
    }

    .nav-active & {

        span,
        &:after,
        &:before {
            @include animate (all, .4s 0s);
        }

        span {
            width: 100%;
            top: 50%;
            transform: rotate(-45deg) translateY(0);
            background: transparent;

            &:before,
            &:after {
                @include animate (all, .4s 0s);
                opacity: 1;
            }
        }

        &:before {
            width: 100%;
            top: 50%;
            transform: rotate(45deg) translateY(0);
            background: $white;
        }

        &:after {
            opacity: 0;
        }
    }
}